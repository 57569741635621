.comments-wrapper{
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 20px 0;
}

.comment-input {
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.comment-input textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    resize: vertical;
}

.comment-input button {
    background-color: #753f7c;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}

.comments-header {
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #eee;
}

.comments-list .comment {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.comments-list .comment:last-child {
    border-bottom: none;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.comment-content {
    flex: 1;
}

.sender-name {
    font-weight: bold;
}

.comment-text {
    display: block;
    margin-top: 5px;
}
.comment-info {
    display: grid;
    grid-template-columns: 1fr .3fr;
    padding: 10px 0;

}
.like-share-wrap{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: right;
}

.like-btn, .share-btn {
    color: #753f7c;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    float: right;  /* Make the buttons float to the right */
    text-align: right;
    font-size: 12px;
}
.share-btn {
    margin-right: 20px;
}
.comment-date {
    padding-left: 5px;  /* Add padding to the left */
    padding-right: 5px;  /* Add padding to the right */
    font-size: 10px;
    padding-top: 7px;
}

.comment-replies {
    margin-top: 10px;
}

.reply-input {
    margin-top: 5px;
}

.reply {
    margin-left: 40px; /* or any value that you see fit */
    margin-top: 5px;
}