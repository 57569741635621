@keyframes testimonialSlider {
  0% {
    margin-left: 0%;
    opacity: 0.2;
  }
  5% {
    margin-left: 0%;
    opacity: 0.5;
  }
  10% {
    margin-left: 0%;
    opacity: 1;
  }
  15% {
    margin-left: 0%;
    opacity: 1;
  }
  20% {
    margin-left: 0%;
    opacity: 1;
  }
  25% {
    margin-left: -100%;
  }
  30% {
    margin-left: -100%;
  }
  35% {
    margin-left: -100%;
  }
  40% {
    margin-left: -100%;
  }
  45% {
    margin-left: -100%;
  }
  50% {
    margin-left: -200%;
  }
  55% {
    margin-left: -200%;
  }
  60% {
    margin-left: -200%;
  }
  65% {
    margin-left: -200%;
  }
  70% {
    margin-left: -200%;
  }
  75% {
    margin-left: -300%;
  }
  75% {
    margin-left: -300%;
  }
  80% {
    margin-left: -300%;
  }
  85% {
    margin-left: -300%;
  }
  90% {
    margin-left: -300%;
  }
  95% {
    margin-left: -300%;
  }
  100% {
    margin-left: -400%;
  }
  100% {
    margin-left: -400%;
  }
  100% {
    margin-left: -400%;
  }
  100% {
    margin-left: -400%;
  }
  100% {
    margin-left: -300%;
  }
  100% {
    margin-left: -200%;
  }
  100% {
    margin-left: -100%;
  }
  100% {
    margin-left: 0%;
  }
}


@keyframes outerloading {
  from {
    border-top: 5px solid #1b6327;
    border-bottom: 5px solid #1b6327;
    border-right: 5px solid #ffffff;
    border-left: 5px solid #ffffff;
    transform: rotate(0deg);
  }
  to {
    border-top: 5px solid #ffffff;
    border-bottom: 5px solid #ffffff;
    border-right: 5px solid #1b6327;
    border-left: 5px solid #1b6327;
    transform: rotate(360deg);
  }
}
@keyframes innerloading {
  0% {
    border-top: 5px solid #ffffff;
    border-bottom: 5px solid #ffffff;
    border-right: 5px solid #1b6327;
    border-left: 5px solid #1b6327;
    transform: scale(0.5) rotate(360deg);
    box-shadow: 0 0 20px 20px #ffffff;
  }
  50% {
    border-top: 5px solid #1b6327;
    border-bottom: 5px solid #1b6327;
    border-right: 5px solid #ffffff;
    border-left: 5px solid #ffffff;
    transform: scale(0.9) rotate(0deg);
    box-shadow: 0 0 40px 40px rgba(87, 7, 97, 0.1);
  }
  100% {
    border-top: 5px solid #1b6327;
    border-bottom: 5px solid #1b6327;
    border-right: 5px solid #ffffff;
    border-left: 5px solid #ffffff;
    transform: scale(0.5) rotate(-360deg);
    box-shadow: 0 0 40px 40px rgba(87, 7, 97, 0.5);
  }
}
@keyframes toploading_indicator {
  from{
      width: 0%;
      opacity: 1;
      display: block;
  }
  to{
      background-color: #054d12;
      width: 100%;
      opacity: 0.6;
      display: block;

  }
}


@keyframes image_loading_bounce {
  0%{
      transform: scale(0.2) rotate(0deg);
  }
  20%{
      transform: scale(0.9) rotate(0deg);
  }
  40%{
      transform: scale(0.2) rotate(0deg);
  }
  60%{
      transform: scale(0.9) rotate(0deg);
  }
  80%{
      transform: scale(0.2) rotate(0deg);
  }
  100%{
      transform: scale(0.9) rotate(0deg);
  }
}

@keyframes blog_image_zooming {
  from{
    transform: scale(1);
  }
  to{
    transform: scale(1.5);
  }
  
}