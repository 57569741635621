.pageloading {
    width: 100vw;
    height: 100vh;
    background-color: rgba(129, 86, 86,0.4);
    position: fixed;
    z-index: 3000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon_loading_gif {
    background-color: transparent;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 5px solid #1b6327;
    position: relative;
    animation: 1s outerloading 1s infinite;
  }
  .icon_loading_spin {
    background-color: transparent;
    width: 48.5px;
    height: 48.5px;
    border-radius: 50%;
    border: 5px solid #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: 2s innerloading 1s infinite;
  }
  .top_loading{
      width: 100%;
      background-color: #50166c;
      height: 10px;
      position: absolute;
      top: 0;
      animation: 1s toploading_indicator linear;
      left: 0;
      display: block;
  }
  .icon_loading_spin img{
      border-radius: 50%;
      width: 37px;
      height: 37px;
      animation: 0.8s image_loading_bounce infinite;
  }
  
  /* short loading image */
  .loading-indicator-wrap{
    background-color: #ffffff;
    padding: 40px;
  }
  .loading-indicator{
    display: flex;
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    align-items: center;
    align-content: center;
    position: relative;
    margin: 0 auto;
  }
  .loading-indicator img{
    flex: auto;
    position: relative;
    height: 50px;
    width: 50px;
  }