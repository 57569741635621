@media (min-width: 275px) AND (max-width: 1180px) {
  #mobile_toggle_icon {
    display: block;
    position: fixed;
    background-color: transparent;
    color: #d300ea;
    width: 100%;
    text-align: end;
    padding: 0 20px;
    z-index: 2000;
  }
  #mobile_toggle_icon #btnIcon {
    font-size: 24px;
    padding: 5px 12px;
    width: 100px;
    background-color: rgba(255, 255, 255,  1);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    box-shadow: 1px 1px 2px #e1e1e1;
    color: #570761;
    z-index: 2000;
  }
  .nav-container {
    width: 250px;
    max-width: 250px;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    z-index: 2000;
    left: 0;
    overflow-y: auto;
    border-right: 1px solid #f1f1f1;
    display: none;
    animation: 0.3s mobileMenu linear;
  }
  @keyframes mobileMenu {
    from {
      margin-left: -250px;
      opacity: 0.3;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  .sticky {
    position: fixed;
  }
  @keyframes stickyheader {
    from {
      margin-top: -100px;
      opacity: 0.5;
    }
    to {
      margin-top: 0;
      opacity: 1;
    }
  }

  .nav-content-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    background-color: transparent;
  }
  /*logo style*/
  .logo-wrapper {
    width: 100%;
    height: 200px;
    text-align: center;
    min-width: 120px;
    background-color: transparent;
    padding: 10px;
    display: flex;
    flex: content;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    background: url("./../images/mic_man.jpg") no-repeat;
    background-size: cover;
  }
  .logo-wrapper a {
    display: block;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5%;
    box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.6);
  }
  .logo-wrapper a:hover {
    background-color: #ffffff;
    transition: 0.5 background-color linear;
  }
  .logo-wrapper a img {
    width: 200px;
    position: relative;
    padding: 60px 10px 10px 10px;
    filter: contrast(220%) brightness(70%) !important;
  }
  /* nav menus */
  .nav-wrapper {
    padding: 0 0 50px 0;
    margin: 20px 0 50px 0;
    background-color: transparent;
    border-bottom: 1px solid #f1f1f1;
  }
  .nav-menu-ul {
    list-style-type: none;
  }
  .nav-menu-ul li {
    list-style-type: none;
    display: inline;
    text-align: left;
  }
  .nav-anchor-tag {
    display: inline;
    text-align: left;
  }
  .nav-menu-ul li a {
    border-left: 4px solid transparent;
    border-top: none;
    text-align: left;
    font-size: 13px;
    padding: 20px 15px;
    margin: 0;
    font-family: "Poppins-bold";
    text-transform: capitalize;
    opacity: 0.8;
  }

  /* login register buttons */
  .sign-reg-btn-wrapper {
    margin: 0;
    min-width: 200px;
  }
  .auth-btns {
    display: inline-block;
    width: 100%;
    list-style-type: none;
    text-align: right;
    position: absolute;
  }
  .auth-btns li {
    display: inline-block;
    margin-top: 24px;
  }
  .auth-btns li a {
    min-width: 300px;
    color: #570761;
    text-align: center;
    padding: 10px 20px;
    border: 1px solid #570761;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 10px;
    font-weight: bold;
    font-family: "Poppins-bold";
  }
  .auth-btns li a:hover {
    color: #ffffff;
    background-color: #570761;
  }
  .auth-btns li:last-child a {
    background-color: #570761;
    color: #ffffff;
    padding: 10px 10px;
    border-radius: 5px;
    margin-right: none;
    border: 1px solid #570761;
  }
  .auth-btns li:last-child a:hover {
    background-color: transparent;
    border: 1px solid #d300ea;
    color: #d300ea;
  }

  #active {
    background-color: rgb(87, 7, 97, 0.05);
    position: relative;
    border-top: none;
    border-left: 4px solid transparent;
    border-left-color: #570761;
    color: #570761;
    transition: border-left-color 2s ease; /* Transition for border color change */
  }

  #active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    background-color: #570761;
    transition: width 2s ease;
  }

  .nav-menu-ul li a:hover {
    border-left-color: #570761;
    color: #570761;
    transition: border-top-color 2s ease;
  }
  .image-wrapper {
    width: 100%;
    position: relative;
    padding-top: 15%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .slider-btn {
    margin-top: 20px;
  }

  .slider-btn a {
    padding: 10px 5px;
    font-size: 10px;
    font-weight: bold;
    font-family: "Poppins-bold";
    min-height: 50px;
    text-align: center;
  }
  .social-contact-image-wrapper img {
    width: 100%;
    margin: 0 auto;
  }
  .social-contact-btn-wrapper {
    grid-gap: 5px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .social-contact-btn-wrapper a{
    font-size: 10px;
    height: 30px;
    padding: 7px 0px;
    border-radius: 5px;
  }
  .start-selling-section-text a,
  .control-of-your-music-container a {
    font-size: 10px;
    height: 30px;
    padding: 7px 5px;
    border-radius: 5px;
  }
  .control-of-your-music-text-wrapper h1 {
    margin: 40px auto 0 auto;
    color: #f1f1f1;
    font-weight: bold;
    font-family: "Poppins-bold";
    font-size: 27px;
    max-width: 700px;
    text-align: center;
  }
  .testimonial-slider-items {
    width: 80%;
  }
  .item-pricing-wrap{
    width: 94%;
  }
  
}
