.artistes-jumbo-wrapper {
    height: 300px;
    background-color: rgba(87, 7, 97, 0.5);
    text-align: center;
}

.artistes-jumbo-overlaybg {
    width: 100%;
    height: 100%;
    position: relative;
}

.artistes-jumbo-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    height: 300px;
}

.artistes-jumbo-container h1 {
    text-align: center;
    color: #ffffff;
    flex: 1;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 35px;
    text-shadow: 0 0 2px 3px #753f7c;
}

.artist-advice-wrap {
    background-color: #ffffff;
}
.blog-view-wrapper{
    background-color: #ffffff;
}
.blog-section-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #f9f9f9;
}

.blog-items-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    padding-top: 20px;
}

.blog-items {
    margin: 10px;
    border: 1px solid #f1f1f1;
}

.blog-items:hover {
    opacity: 0.9;
    box-shadow: 0 0 10px 1px #ddd9de;
    transition: 1s box-shadow;
}

.blog-item-image-wrapper {
    min-height: 200px;
    background: url(./../images/loading2.gif) no-repeat;
    background-position: center;
    background-size: 10% 10%;
}

.blog-item-image-wrapper img {
    border-top: 4px solid #753f7c;
}

.blog-text-content-wrapper {
    margin: 2px 0;
    padding: 10px;
}

.blog-text-content-wrapper h1 {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 5px;
}
.date-and-readmore-wrapper{
    width: 100%;
    position: relative;
    clear: both;
    padding: 15px 10px 10px 0;
    font-size: 12px;
}
.blog-readmore{
    text-align: right;
    font-size: 14px;
}
.date-and-readmore-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 5px 0;
    font-style: italic;
}

/* blog details view */
.blog-details-view-component {
    width: 100%;
    background-color: #f9f9f9;
    height: 100%;
    padding: 20px;
    position: relative;
    clear: both;
}

.blog-view-content {
    display: grid;
    grid-template-columns: 1fr;
}

.blog-title-and-date-wrap {
    display: grid;
    grid-template-columns: 1fr;
}

.blog-title-and-date-wrap h1 {
    padding: 20px 0;
    border-bottom: 1px solid #f1f1f1;
    border-radius: 10px;
    font-weight: bold;
    color: #000000;
    font-size: 24px;
}

.author-and-date-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0;
}
.author-and-date-wrap span{
    font-style: italic;
    font-size: 12px;
    color: #555555;
}
.blog-detail-view-image{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
    background: url(./../images/loading2.gif) no-repeat;
    background-position: center;
    background-size: 10% 10%;
}
.blog-detail-view-content{
    width: 100%;
    padding: 20px 0;
    line-height: 24px;
    font-size: 16px;
}
.blog-detail-view-content a{
    text-decoration: none;
    color: #753f7c;
    cursor: pointer;
    font-style: italic;
    padding: 2px 5px;
}