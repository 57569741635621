.nav-container{
    width: 100%;
    max-width: 100%;
    height: 70px;
    background-color: #ffffff;
    position: relative;
    display: block;
}
.sticky{
   position: fixed;
   z-index: 2500; 
   height: 70px;
   animation: 0.5s stickyheader linear;
}
@keyframes stickyheader {
    from{
        margin-top: -100px;
        opacity: 0.5;
    }to{
        margin-top: 0;
        opacity: 1;
    }
}
.nav-content-wrapper{
    display: grid;
    grid-template-columns: 1.5fr 6fr 2.8fr;
    background-color: transparent;
}
/*logo style*/
.logo-wrapper{
    width: 100%;
    height: auto;
    text-align: center;
    min-width: 120px;
    background-color: transparent;
}
.logo-wrapper a{
    display: block;
}
.logo-wrapper a img{
    width: 200px;
    position: relative;
    padding:3px 10px 10px 10px;
    filter: contrast(220%) brightness(70%) !important;
}
/* nav menus */
.nav-wrapper{
    padding: 0;
    margin: 0;
    background-color: transparent;
}
.nav-menu-ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.nav-menu-ul li{
    list-style-type: none;
    display: inline-block;
    padding: 0;
    margin: 0;
}
.nav-anchor-tag{
    display: inline;
    position: relative;
    float: left;
}
.nav-menu-ul li a{
    text-decoration: none;
    display: block;
    padding: 24px 15px;
    color: #000000;
    border-top: 4px solid transparent;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    font-family: "Poppins-bold";
}

/* login register buttons */
.sign-reg-btn-wrapper{
    margin: 0;
    min-width: 200px;
}
.auth-btns{
    display: inline-block;
    width: 100%;
    list-style-type: none;
    text-align: right;
}
.auth-btns li{
    display: inline-block;
    margin-top: 24px;
}
.auth-btns li a{
    min-width: 200px;
    color: #570761;
    text-align: center;
    padding: 10px 20px;
    border: 1px solid #570761;
    border-radius: 5px;
    margin-right: 10px;
    
}
.auth-btns li a:hover{
    color: #ffffff;
    background-color: #570761;
}
.auth-btns li:last-child a{
    background-color: #570761;
    color: #ffffff;
    padding: 10px 10px;
    border-radius: 5px;
    margin-right: none;
    border: 1px solid #570761;
}
.auth-btns li:last-child a:hover{
    background-color: transparent;
    border: 1px solid #d300ea;
    color: #d300ea;
}

#active {
    background-color: rgb(87, 7, 97,0.01);
    position: relative;
    border-bottom: 4px solid transparent;
    border-top-color: #570761;
    color: #570761;
    transition: border-top-color 2s ease; /* Transition for border color change */
  }
  
  #active::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    background-color: #570761;
    transition: width 2s ease; 
  }

  .nav-menu-ul li a:hover{
    border-top-color: #570761; 
    color: #570761;
    transition: border-top-color 2s  ease;
}
