* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins-Light";
}
a {
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: #570761;
}
body {
  height: 100%;
  padding: 0;
  margin: 0;
}
.body-bg {
  width: 100%;
  height: 100%;
  position: relative;
  background: url("./../images/woman2.webp") no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-blend-mode: hard-light;
}
#mobile_toggle_icon{
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
ul li {
  list-style: none;
  display: inline-block;
}
.container {
  width: 100%;
  max-width: 95%;
  min-width: 5px;
  background-color: transparent;
  position: relative;
  margin: 0 auto;
  z-index: 1000;
}
.container-wrapper {
  width: 100%;
  height: 100vh;
  max-height: 90vh;
  margin: 0;
  padding: 0;
  /*background-color: rgba(87, 7, 97, 0.2);*/
  border-bottom: 1px solid #f1f1f1;
}
.image-wrapper {
  width: 100%;
  position: relative;
  padding-top: 15%;
  padding-left: 10%;
}
.slider-text-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 6%;
}
.image-wrapper h1 {
  color: #f1f1f1;
  font-size: 48px;
  max-width: 600px;
  font-family: "Poppins-bold";
  text-shadow: 1px 1px 1px #570761;
}
.image-wrapper p {
  color: #f1f1f1;
  padding-top: 10px;
  max-width: 600px;
}
.slider-btn-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 700px;
  margin-top: 20px;
  grid-gap: 20px;
}
.slider-btn {
  text-align: center;
  color: #ffffff;
}
.slider-btn a {
  padding: 10px 30px;
  display: block;
  border-radius: 10px;
}
.slide-btn-last {
  background-color: #f1f1f1;
  color: #570761;
  border-radius: 10px;
  border: 1px solid #570761;
  font-weight: bold;
  box-shadow: inset 10px 10px 10px #570761 1px 2px 3px #000000;
}
.slide-btn-first {
  background-color: #570761;
  color: #ffffff;
  border-radius: 10px;
  border: 1px solid #ffffff;
  font-weight: bold;
  box-shadow: inset 10px 10px 10px #570761 1px 2px 3px #000000;
}
.slide-btn-first:hover {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  transition: 1s background-color;
}
.slide-btn-last:hover {
  background-color: #570761;
  color: #ffffff;
  border: 1px solid #ffffff;
  transition: 1s background-color;
}
.slider-wrapper{
    background-color: rgba(87, 7, 97,0.3);
}
.content-main-wrapper{
  width: 100%;
  position: relative;
  clear: both;
  height: auto;
  background-color: #ffffff;
  margin-bottom: 0;
}
/* social media icons section */
.social-section-image{
  width: 100%;
  min-width: 100%;
  position: relative;
  height: auto;
  min-height: 300px;
  background-color: #ffffff;
  clear: both;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}
.social-icons-to-content{
  padding: 35px 5%;
  text-align: center;
}
.social-icons-to-content h1{
  font-size: 35px;
  padding: 10px 0;
  margin: 10px;
  font-weight: bold;
  color: #000000;
  font-family: "Poppins-bold";
}
.social-icons-to-content p{
  color: #070707;
  font-family: "Poppins-light";
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}
.social-images {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
  margin: 20px auto;
  padding: 0 40px;
}

.social-images img {
  object-fit: contain;
  height: 50px;
  min-height: 40px;
  width: 40%;
  min-width: 120px;
}
/* start selling music section */
.start-selling-section{
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
  clear: both;
  background-color: #ffffff;
  padding: 50px auto;
  margin-bottom: 40px;
}
.start-selling-wrap{
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}
.start-selling-section-image{
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
}
.start-selling-section-image img{
  width: 100%;
  max-width: 400px;
}
.start-selling-section-text{
  width: 100%;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  padding-top: 10%;
}
.start-selling-section-text h1{
  color: #000000;
  font-family: "Poppins-bold";
  font-size: 28px;
}
.start-selling-section-text p{
  text-align: center;
}

.start-selling-section-text a{
  margin: 5% auto;
  display: block;
  text-decoration: none;
  min-width: 200px;
  padding: 10px;
  color: #570761;
  background-color: transparent;
  border-radius: 10px;
  font-weight: bold;
  font-family: "Poppins-bold";
  border: 1px solid #570761;
}
.start-selling-section-text a:hover{
  background-color: #570761;
  border: 1px solid transparent;
  color: #ffffff;
}



/* why choose us section */
.choose-us-section{
  width: 100%;
  position: relative;
  clear: both;
  height: auto;
  background-color: #f1f1f1;
  margin: 10px 0;
  padding: 4% 0;
}
.choose-us-container {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
}
.choose-us-items-wrap{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}
.choose-us-header{
  margin-bottom: 30px;
}
.choose-us-header h1{
  font-weight: bold;
  font-family: "Poppins-bold";
  color: #080808;
  font-size: 28px;
}
.choose-us-header h3{
  font-weight: bold;
  font-family: "Poppins-bold";
  color: #570761;
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid #570761;
  width: 150px;
  margin: 0 auto;
}
.choose-us-items{
  width: 100%;
}
.choose-us-items span{
  width: 150px;
  height: 150px;
  background-color: #000000;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 20px;
  color: #f1f1f1;
  border: 2px solid #570761;
  filter: drop-shadow(100%);

}

.choose-us-items h1{
  margin: 30px auto 10px auto;
  color: #080808;
  font-weight: bold;
  font-family: "Poppins-bold";
}
/* social contact */
.social-contact-wrapper{
  width: 100%;
  position: relative;
  clear: both;
  background-color: #ffffff;
  height: auto;
  margin: 10px auto;
}
.social-contact-container{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}
.social-contact-image-wrapper{
  padding: 10px;
}
.social-contact-image-wrapper img{
  width: 40%;
  margin: 0 auto;
}
.social-contact-right-content{
  display: grid;
  grid-template-columns: 1fr;
}
.social-contact-text-wrapper{
  padding: 20px;
  text-align: left;
}
.social-contact-text-wrapper h1{
  width: 200px;
  margin: 40px 0 10px 0;
  color: #080808;
  font-weight: bold;
  font-family: "Poppins-bold";
  border: 1px dashed #570761;
  padding: 5px 10px;
  text-align: center;
  border-radius: 5px;
}
.social-contact-text-wrapper h2{
  margin: 10px 0 5px 0;
  color: #080808;
  font-weight: bold;
  font-family: "Poppins-bold";
  padding: 5px 0;
  font-size: 28px;
  text-align: left;
}
.social-contact-btn-wrapper{
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 20px;
  padding: 0 20px;
}
.social-contact-btn-wrapper a{
  padding-top: 10px;
  display: block;
  border: 1px solid #570761;
  text-align: center;
  border-radius: 10px;
  height: 50px;
  color: #000000;
  font-weight: bold;
  font-family: "Poppins-bold";
}
.social-contact-btn-wrapper a:last-child{
  background-color: #570761;
  color: #ffffff;
}
.social-contact-btn-wrapper a:hover{
  background-color: #570761;
  color: #ffffff;
  transition: .5s background-color linear;
}
.social-contact-btn-wrapper a:last-child:hover{
  background-color: #ffffff;
  color: #570761;
  transition: .5s background-color linear;
}

/* control of your music wrapper */
.control-of-your-music-wrapper{
  margin: 20px auto 0 auto;
  border-top: 5px solid #570761;
  background: url('./../images/take_control_bg.webp') repeat-x;
  background-position: center;
  background-size: 100% 280%;
  position: relative;
  clear: both;
  width: 100%;
  height: auto;
  min-height: 300px;
}
.control-of-your-music-overlay{
  width: 100%;
  background-color: rgba(87, 7, 97,0.3);
  height: 100%;
  min-height: 300px;
  position: absolute;
  clear: both;
}
.control-of-your-music-container{
  text-align: center;
  padding: 20px auto;
}
.control-of-your-music-text-wrapper{
  position: relative;
  display: block;
  width: 100%;
  padding: 30px auto;
  margin-bottom: 40px;
}
.control-of-your-music-text-wrapper h1{
  margin: 30px auto;
  color: #f1f1f1;
  font-weight: bold;
  font-family: "Poppins-bold";
  font-size: 28px;
  max-width: 700px;
}
.control-of-your-music-text-wrapper p{
  color: #f1f1f1;
}
.control-of-your-music-container a{
  text-decoration: none;
  padding: 10px;
  color: #ffffff;
  background-color: #570761;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}
.control-of-your-music-container a:hover{
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #570761;
  transition: .5s background-color linear;
}
/* testimonial section */
.testimonial-wrapper{
  background-color: #ffffff;
  height: auto;
  position: relative;
  clear: both;
}
.testimonial-container{
  display: grid;
  grid-template-columns: 1fr;
  margin: 20px auto;
  padding: 30px auto;
  text-align: center;
  background: url('./../images/testimonial_bg.webp') repeat;
  background-position: center;  margin-top: 20px;
  padding-bottom: 40px;
 
}
.testimonial-header h1{
  font-size: 28px;
  font-weight: bold;
  font-family: "Poppins-bold";
  color: #080808;
}
.testimonial-content-slide{
  width: 100%;
  position: relative;
  clear: both;
  overflow: hidden;

}
.testimonial-slider{
  position: relative;
  width: 400%;
  height: 100%;
  min-height: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  top: 0;
  bottom: 0;
  padding: 10px;
  text-align: center;
  animation: 20s testimonialSlider infinite;
  overflow: hidden;
}
.testimonial-slider:hover{
  animation-play-state: paused;
}
.testimonial-slider-items{
  width: 30%;
  margin: 0 auto;
}
.testimonial-slider-items p{
  padding-bottom: 20px;
}
.testimonial-slider-items strong{
  font-weight: bold;
  font-family: "Poppins-bold";
  color: #080808;
}
/* sell your music online page styling
 */
.sell-music-online-wrapper{
  min-height: 90vh;
  width: 100%;
  background-color: rgba(255,255,255,1);
  text-align: center;
  padding-top: 30px;
}
.pricing-table-wrapper{
  background-color: #ffffff;
  padding: 20px 20px 100px 20px;
}
.pricing-table-wrapper{
  width: 100%;
}
.pricing-table-wrapper > h1{
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #570761;
  padding: 10px 10px;
  width: 200px;
  margin: 0 auto 20px auto;
}
.price-list-wrapper{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 40px;
}
.pricing-items{
  width: 100%;
  margin-top: 20px;
  min-height: 300px;
  border-radius: 10px;
  padding: 20px 10px;
  position: relative;
  margin-bottom: 160px;
  background-color: #ffffff;
  box-shadow: 0 0 15px 1px #f1f1f1;
}
.pricing-items>h1{
  color: #570761;
  font-weight: bold;
  padding: 10px;
  margin-left: 10px;
}
.pricing-details-wrap{
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.item-number{
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  top: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: rotate(45deg);
  height: 80px;
  width: 80px;
  background-color: #570761;
  border-radius: 10px;
  border: 2px solid #e1e1e1;
  z-index: 2;
}
.item-number h1{
  position: relative;
  color: #ffffff;
  font-size: 34px;
  height: 45px;
  width: 70px;
  transform: rotate(-45deg);
  text-align: center;
}
.item-pricing-wrap{
  position: absolute;
  top: 60px;
  padding: 60px 20px 20px 20px;
  width: 95%;
  z-index: 1;
  border-radius: 10px;
}

.item-pricing-wrap > h1{
  color: #e1e1e1;
  font-weight: bold;
  font-size: 28px;
}
.pricing-feature-wrapper{
  text-align: left;
  color: #e1e1e1;
  padding: 10px 30px;
}
.pricing-feature-wrapper li{
  line-height: 36px;
}
.pricing-details-wrap a{
  position: relative;
  z-index: 4;
  top:136%;
  color: #ffffff;
  padding: 5px 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: 0 0 10px 1px #bfb9b9;
}
.pricing-details-wrap a:hover{
  background-color: #ffffff;
  color: #570761;
  box-shadow: 0 0 1px 2px #570761;
  transition: .5s background-color;
}
.item-pricing-wrap-bg1{
  background-color: #570761;
}
.item-pricing-wrap-bg2{
  background-color: #396c69;
}
.item-pricing-wrap-bg3{
  background-color: #94523c;
}
.item-pricing-wrap-bg4{
  background-color: #3e55a0;
}
/* pricing table two */
.pricing-two-table-container{
  width: 100%;
  position: relative;
  min-height: 300px;
  height: 100%;
  background-color: #d4f4e4;
  clear: both;
  padding-bottom: 50px;
}
.price-two-list-wrapper{
  width: 100%;
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  padding: 20px 0 50px 0;
}
.price-two-list-wrap{
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(250px, 1fr));
  grid-gap: 50px;
  padding: 30px 0 20px 0;
}
.price-two-items{
  background-color: #ffffff;
  min-height: 400px;
  height: 100%;
  border-radius: 10px;
  margin: 5px 25px;
  box-shadow: 13px 13px 1px 2px rgba(188, 205, 203, 0.5);
}
.price-two-title-and-amount-wrap{
  padding: 40px 5px;
  display: grid;
  grid-template-columns: 2fr 1.4fr;
  text-align: left;
  margin-top: 30px;
}
.price-two-title-and-amount-wrap span{
  background-color: #d4f4e4;
  margin-left: -30px;
  padding: 5px 20px 5px 35px;
  font-size: 16px;
  border-radius: 10px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}
.price-two-title-and-amount-wrap strong{
  font-weight: bold;
  padding: 11px;
  font-size: 26px;
}
.price-two-feature-list-wrap{
  text-align: left;
  padding: 10px 10px 10px 45px;
}
.price-two-feature-list-wrap li{
  list-style-type: "\2713";
  padding-left: 5px;
  font-size: 14px;
  line-height: 34px;
}
.price-two-items a{
  text-transform: uppercase;
  padding: 10px 5px;
  display: block;
  font-size: 24px;
  font-weight: bold;
}
.price-two-unlimited-forever{
  background-color: #f8a6b7 !important;
  padding: 10px 30px !important;
}
.price-two-easybuy{
  background-color: #fbf6a9 !important;
  line-height: 50px;
  font-weight: bolder !important;
}

/* footer */
.footer-section{
  overflow-x: hidden;
  width: 100%;
  position: relative;
  clear: both;
  height: auto;
  min-height: 80px;
  background-color: #f9f9f9;
  padding: 10px auto;
  margin: 10px auto;
}
.footer-section-container{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
  grid-gap: 10px;
}
.footer-copyright{
  text-align: center;
  margin-top: 30px;
}
.footer-copyright a{
  padding: 0 10px;
  font-size: 12px;
}