.error-page-404-wrapper{
    width: 100%;
    height: 100%;
    min-height: 67vh;
    background-color: #ffffff;
    position: relative;
}
.error-404-content-wrap{
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
    min-height: 67vh;
}

.error-404-content{
    flex: 1;
    text-align: center;
}

.error-404-content h1{
    font-size: 150px;
    font-weight: bold;
    color: #888888;
    text-shadow: 0 0 1px 5px #570761;
    line-height: 130px;
}