/** @format */

.input-container {
  border: 1px solid #f1f1f1;
  display: grid;
  grid-template-columns: 0.5fr 11fr;
  width: 100%;
  min-width: 100%;
  position: relative;
  padding: 5px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.input-container label {
  position: relative;
  display: block;
  text-align: center;
}
.input-container label svg {
  font-size: 18px;
  color: #333333;
  position: absolute;
  top: 5px;
  left: 5px;
  bottom: 0;
  padding-right: 5px;
}
.input-container input {
  outline: none;
  border: none;
  padding: 4px 5px;
  font-size: 14px;
  background-color: transparent;
}
.input-container:hover,
.input-container:focus,
.input-container:active {
  border: 1px solid #570761;
  background-color: #f9f9f9;
  animation: border 10s linear;
}
.input-container input:hover,
.input-container input:active,
.input-container input:focus,
.input-container select:hover,
.input-container select:active,
.input-container select:focus {
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
select::-webkit-outer-spin-button,
select::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"],
select {
  -moz-appearance: textfield;
}

.submit-button{
    background-color: #570761;
    color: #ffffff;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    margin-top: 30px;
    display: block;
}
.submit-button:hover{
    background-color: #861295;
}
.sign_reg_in_linkWrap{
    text-align: center;
    margin: 20px 0;

}
.sign_reg_in_linkWrap a{
    color: #CE11E7;
    font-weight: bold;
    text-transform: capitalize;
}

.parentDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Set the height of the parent div to your desired height */
  }
  
  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  
  .logoImage {
    max-width: 100%; /* You can adjust this to control the maximum width of the logo */
    max-height: 100%; /* You can adjust this to control the maximum height of the logo */
  }
