select {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 30px;
    font-size: large;
    padding: 0 5px;
    color: #222222;
    font-size: 14px;
  }
  select:active,select::after,select::before{
    color: #000000;
  }